@import '_variables.scss';

.userCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  padding: 5px 10px;
  border-radius: 5px;
  height: 50px;

  @media screen and (max-width: $laptop-screen-min) {
    width: 100%;
  }
}

.cardName {
  width: 250px;
}

.cardButtonGroup {
  display: flex;
  justify-content: end;

  > button {
    margin: 0 10px;
  }

  @media screen and (max-width: $laptop-screen-min) {
    width: 500px;
    margin: 0;
    > button {
      margin: 0 5px;
    }
  }
}

#attending {
  min-width: 90px;

  @media screen and (max-width: $phone-screen-min) {
    width: 40px;
    overflow: hidden;
  }

  @media screen and (min-width: $laptop-screen-min) {
    min-width: 150px;
  }
}

#paid {
  @media screen and (max-width: $laptop-screen-min) {
    min-width: 70px;
  }
}

#delete {
  color: #c42525;

  @media screen and (max-width: $laptop-screen-min) {
    display: none;
  }
}

#cross {
  fill: #c42525;
  align-self: center;
  justify-self: center;
  font-size: 30px;
  cursor: pointer;
  opacity: 0.8;
  :hover {
    opacity: 1;
  }
  @media screen and (min-width: $laptop-screen-min) {
    display: none;
  }
}

.deleteConfirmation {
  text-align: center;
  margin-bottom: 10px;
  width: 300px;

  @media screen and (max-width: $laptop-screen-min) {
    width: 100%;
  }
}
