@import '_variables.scss';

.summaryContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: $laptop-screen-min) {
    flex-direction: column;
  }
}

h5 {
  margin: 0px;
}

.time {
  margin-left: 5px;
  font-size: 12px;
  opacity: 0.8;
}

.statsGroup {
  display: flex;
}

.buttonGroup {
  @media screen and (max-width: $laptop-screen-min) {
    > button {
      margin: 0 20px;
    }

    :first-child {
      margin: 0px;
    }
  }
}

.leftSide {
  min-width: 130px;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: $laptop-screen-min) {
    justify-content: start;
    align-self: start;
  }
}

.rightSide {
  display: flex;

  @media screen and (max-width: $tablet-screen-min) {
    flex-direction: column;
    align-self: start;
    > div:first-child {
      margin: 10px 0px;
    }
  }
}

.marginStats {
  width: 50px;
  margin: 0 20px;
  @media screen and (max-width: $laptop-screen-min) {
    margin: 0 10px;
  }
}

.marginMobile {
  @media screen and (max-width: $laptop-screen-min) {
    margin-left: 0px;
  }
}

.columnItem {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.textCenter {
  text-align: center;
}

.description {
  font-size: 14px;
}

.detailBox {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.detailRow {
  display: flex;
  @media screen and (max-width: $laptop-screen-min) {
    flex-direction: column;
  }
}

.searchField {
  margin: 30px 20px;
}

.eventToolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 40px;
}
