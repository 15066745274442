@import '_variables.scss';

.containerManage {
  display: flex;
  flex-direction: column;
  width: 90%;

  @media screen and (max-width: $laptop-screen-min) {
    width: 100%;
  }
}

.rowManage {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  > div:nth-child(even) {
    background: #dedede;
  }
}

.sortComponent {
  margin-left: 30px;
  align-self: flex-start;
  cursor: pointer;

  @media screen and (max-width: $laptop-screen-min) {
    margin-left: 0;
  }
}

.toolbar {
  width: 100%;
  display: flex;
  margin-top: 30px;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: $laptop-screen-min) {
    margin-top: 0px;
    justify-content: space-around;
  }
}

.filters {
  margin-right: 135px;
  background: white;
  * {
    background: white;
    margin: 0 10px;
  }

  @media screen and (max-width: $laptop-screen-min) {
    margin-right: 0px;
  }
}

.inputRow {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 20px;

  @media screen and (max-width: $laptop-screen-min) {
    flex-direction: column;

    > div {
      width: 100%;
    }
  }
}

.addUser {
  > button {
    height: 40px;

    @media screen and (max-width: $laptop-screen-min) {
      margin-top: 15px;
      height: 45px;
    }
  }

  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $laptop-screen-min) {
    justify-content: flex-start;
  }

  > div {
    width: 300px;
    margin: 0 10px;

    @media screen and (max-width: $laptop-screen-min) {
      margin-left: 0px;
      margin-top: 15px;
    }
  }
}

.edit {
  cursor: pointer;

  @media screen and (min-width: $laptop-screen-min) {
    display: none;
  }
}

.dialogtitle {
  padding: 20px;
  font-size: 25px;

  @media screen and (max-width: $laptop-screen-min) {
    font-size: 20px;
  }
}
