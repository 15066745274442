@import '_variables.scss';

.createAccount-box {
  .login-header {
    text-align: center;

    p,
    h2 {
      margin: 0;
    }
  }

  .input {
    margin-top: 15px;
    width: 100%;
  }

  #sign-in,
  #remember-me {
    margin-top: 10px;
  }

  #phone {
    margin-top: 15px;
    margin-left: 0;
  }

  @media only screen and (max-width: $laptop-screen-min) {
    .footer-content {
      visibility: hidden;
    }

    #create-button {
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
}
