::-webkit-scrollbar {
  display: none;
}

#root {
  min-height: 100vh;
  position: relative;
}

#root .content {
  overflow: hidden;
}

@media only screen and (max-width: 1280px) {
  #root {
    background-color: #fff;
  }
}

html, body {
  background-color: #fafafa;
  margin: 0;
  padding: 0;
  font-family: Lato, sans-serif;
}

a:active, a:focus, input {
  outline: 0;
}

.createAccount-box .login-header {
  text-align: center;
}

.createAccount-box .login-header p, .createAccount-box .login-header h2 {
  margin: 0;
}

.createAccount-box .input {
  width: 100%;
  margin-top: 15px;
}

.createAccount-box #sign-in, .createAccount-box #remember-me {
  margin-top: 10px;
}

.createAccount-box #phone {
  margin-top: 15px;
  margin-left: 0;
}

@media only screen and (max-width: 960px) {
  .createAccount-box .footer-content {
    visibility: hidden;
  }

  .createAccount-box #create-button {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

.container {
  width: 100%;
  flex-direction: column;
  display: flex;
}

.row {
  width: 100%;
  align-items: center;
  display: flex;
}

.row > div {
  margin-bottom: 20px;
  margin-left: 15px;
  margin-right: 15px;
}

.row > label {
  min-width: 150px;
  margin-bottom: 20px;
  margin-right: 15px;
}

@media screen and (max-width: 960px) {
  .row {
    flex-direction: column;
    align-items: flex-start;
  }

  .row > div {
    margin-left: 0;
  }
}

.button {
  justify-content: end;
  margin-top: 20px;
  display: flex;
}

.button > button {
  margin-left: 20px;
  margin-right: 4px;
}

.filterContainer {
  width: 90%;
  height: 50%;
  z-index: 5;
  background: #fff;
  border-width: 1px;
  border-top-style: solid;
  border-top-color: #d52941;
  border-radius: 6px;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
}

.buttonGroup {
  text-align: end;
}

.buttonGroup > button {
  margin: 10px;
}

.alternatives {
  grid-template-columns: 1fr 1fr;
  padding: 0 15px 15px;
  font-size: 14px;
  display: grid;
}

.filterpageContainer {
  display: grid;
  position: relative;
}

.subtitle {
  margin: 10px;
  font-size: 18px;
}

.filterContainer {
  width: 90%;
  height: 50%;
  z-index: 5;
  background: #fff;
  border-width: 1px;
  border-top-style: solid;
  border-top-color: #d52941;
  border-radius: 6px;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
}

.buttonGroup {
  text-align: end;
}

.buttonGroup > button {
  margin: 10px;
}

.alternatives {
  grid-template-columns: 1fr 1fr;
  padding: 0 15px 15px;
  font-size: 14px;
  display: grid;
}

.grouppageContainer {
  display: grid;
  position: relative;
}

.subtitle {
  margin: 10px;
  font-size: 18px;
}

.summaryContainer {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media screen and (max-width: 960px) {
  .summaryContainer {
    flex-direction: column;
  }
}

h5 {
  margin: 0;
}

.time {
  opacity: .8;
  margin-left: 5px;
  font-size: 12px;
}

.statsGroup {
  display: flex;
}

@media screen and (max-width: 960px) {
  .buttonGroup > button {
    margin: 0 20px;
  }

  .buttonGroup :first-child {
    margin: 0;
  }
}

.leftSide {
  min-width: 130px;
  flex-direction: row;
  display: flex;
}

@media screen and (max-width: 960px) {
  .leftSide {
    justify-content: start;
    align-self: start;
  }
}

.rightSide {
  display: flex;
}

@media screen and (max-width: 600px) {
  .rightSide {
    flex-direction: column;
    align-self: start;
  }

  .rightSide > div:first-child {
    margin: 10px 0;
  }
}

.marginStats {
  width: 50px;
  margin: 0 20px;
}

@media screen and (max-width: 960px) {
  .marginStats {
    margin: 0 10px;
  }
}

@media screen and (max-width: 960px) {
  .marginMobile {
    margin-left: 0;
  }
}

.columnItem {
  flex-direction: column;
  align-self: center;
  display: flex;
}

.textCenter {
  text-align: center;
}

.description {
  font-size: 14px;
}

.detailBox {
  flex-direction: column;
  font-size: 14px;
  display: flex;
}

.detailRow {
  display: flex;
}

@media screen and (max-width: 960px) {
  .detailRow {
    flex-direction: column;
  }
}

.searchField {
  margin: 30px 20px;
}

.eventToolbar {
  justify-content: space-between;
  align-items: center;
  margin-right: 40px;
  display: flex;
}

.containerManage {
  width: 90%;
  flex-direction: column;
  display: flex;
}

@media screen and (max-width: 960px) {
  .containerManage {
    width: 100%;
  }
}

.rowManage {
  width: 100%;
  height: 70px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cardContainer {
  width: 100%;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.cardContainer > div:nth-child(2n) {
  background: #dedede;
}

.sortComponent {
  cursor: pointer;
  align-self: flex-start;
  margin-left: 30px;
}

@media screen and (max-width: 960px) {
  .sortComponent {
    margin-left: 0;
  }
}

.toolbar {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 10px;
  display: flex;
}

@media screen and (max-width: 960px) {
  .toolbar {
    justify-content: space-around;
    margin-top: 0;
  }
}

.filters {
  background: #fff;
  margin-right: 135px;
}

.filters * {
  background: #fff;
  margin: 0 10px;
}

@media screen and (max-width: 960px) {
  .filters {
    margin-right: 0;
  }
}

.inputRow {
  justify-content: space-between;
  align-items: center;
  margin: 20px;
  display: flex;
}

@media screen and (max-width: 960px) {
  .inputRow {
    flex-direction: column;
  }

  .inputRow > div {
    width: 100%;
  }
}

.addUser {
  justify-content: center;
  align-items: center;
  display: flex;
}

.addUser > button {
  height: 40px;
}

@media screen and (max-width: 960px) {
  .addUser > button {
    height: 45px;
    margin-top: 15px;
  }
}

@media screen and (max-width: 960px) {
  .addUser {
    justify-content: flex-start;
  }
}

.addUser > div {
  width: 300px;
  margin: 0 10px;
}

@media screen and (max-width: 960px) {
  .addUser > div {
    margin-top: 15px;
    margin-left: 0;
  }
}

.edit {
  cursor: pointer;
}

@media screen and (min-width: 960px) {
  .edit {
    display: none;
  }
}

.dialogtitle {
  padding: 20px;
  font-size: 25px;
}

@media screen and (max-width: 960px) {
  .dialogtitle {
    font-size: 20px;
  }
}

.userCard {
  width: 95%;
  height: 50px;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  display: flex;
}

@media screen and (max-width: 960px) {
  .userCard {
    width: 100%;
  }
}

.cardName {
  width: 250px;
}

.cardButtonGroup {
  justify-content: end;
  display: flex;
}

.cardButtonGroup > button {
  margin: 0 10px;
}

@media screen and (max-width: 960px) {
  .cardButtonGroup {
    width: 500px;
    margin: 0;
  }

  .cardButtonGroup > button {
    margin: 0 5px;
  }
}

#attending {
  min-width: 90px;
}

@media screen and (max-width: 320px) {
  #attending {
    width: 40px;
    overflow: hidden;
  }
}

@media screen and (min-width: 960px) {
  #attending {
    min-width: 150px;
  }
}

@media screen and (max-width: 960px) {
  #paid {
    min-width: 70px;
  }
}

#delete {
  color: #c42525;
}

@media screen and (max-width: 960px) {
  #delete {
    display: none;
  }
}

#cross {
  fill: #c42525;
  cursor: pointer;
  opacity: .8;
  place-self: center;
  font-size: 30px;
}

#cross :hover {
  opacity: 1;
}

@media screen and (min-width: 960px) {
  #cross {
    display: none;
  }
}

.deleteConfirmation {
  text-align: center;
  width: 300px;
  margin-bottom: 10px;
}

@media screen and (max-width: 960px) {
  .deleteConfirmation {
    width: 100%;
  }
}

.themeContainer {
  width: 100%;
  align-items: center;
  margin: 30px;
  display: flex;
}

.themeContainer > div {
  min-width: 300px;
  margin: 10px;
}

.themeContainer > span {
  width: 50px;
  height: 50px;
}

@media screen and (max-width: 960px) {
  .themeContainer {
    width: auto;
    flex-direction: column;
  }

  .themeContainer > span {
    width: 100%;
    height: 30px;
  }
}

.projectSettingsContainer {
  flex-direction: column;
  display: flex;
}

.clearIcon {
  opacity: .8;
  cursor: pointer;
}

.clearIcon :hover {
  opacity: 1;
}

.projectSettings {
  flex-direction: column;
  margin: 30px;
  display: flex;
}

@media screen and (max-width: 960px) {
  .projectSettings {
    margin: 20px;
  }
}

.projectSettings > * {
  margin: 20px 0;
}

.projectSettings > * > * {
  margin: 10px 0;
}

.projectSettings > * > * > * {
  margin-right: 10px;
}

@media screen and (max-width: 960px) {
  .projectSettings > * > * > * {
    min-width: 250px;
    margin-top: 20px;
    margin-right: 0;
  }
}

.projectUpdateButtons {
  place-self: end;
  margin-top: 30px;
}

.indexContainer {
  flex-direction: column;
  margin-right: 40px;
  display: flex;
}

/*# sourceMappingURL=index.81e0c5d0.css.map */
