@import '_variables.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.row {
  width: 100%;
  display: flex;
  align-items: center;

  > div {
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 20px;
  }

  > label {
    min-width: 150px;
    margin-bottom: 20px;
    margin-right: 15px;
  }

  @media screen and (max-width: $laptop-screen-min) {
    flex-direction: column;
    align-items: flex-start;

    > div {
      margin-left: 0px;
    }
  }
}

.button {
  display: flex;
  justify-content: end;
  margin-top: 20px;

  > button {
    margin-left: 20px;
    margin-right: 4px;
  }
}
